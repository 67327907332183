// 导入Vue
import Vue from 'vue'
// 导入路由
import VueRouter from 'vue-router'

var userAgenInfo = navigator.userAgent
var Agents = new Array('Android', 'iPhone', 'SymbianOS', 'iPad', 'iPod')
let flag = false
for (var v = 0; v < Agents.length; v++) {
  if (userAgenInfo.indexOf(Agents[v]) > 0) {
    flag = true
    break
  }
}

let vW = window.innerWidth;
if (vW < 770) {
  flag = true
}

// 导入 组件
// 注册组件
// PC组件
const Home = () => import('../views/home/home.vue')
const Senate = () => import('../views/home/senatePage.vue')
const Alumni = () => import('../views/home/alumniPage.vue')
const Foundation = () => import('../views/home/foundationPage')
const Apply = () => import('../views/home/applyPage.vue')
// mobile组件
const Mobile = () => import('../views/mobile/index')
const SenateMobile = () => import('../views/mobile/senatePage.vue')
const AlumniMobile = () => import('../views/mobile/alumniPage')
const FoundationMobile = () => import('../views/mobile/foundationPage.vue')
const ApplyMobile = () => import('../views/mobile/applyPage')

// 注册
Vue.use(VueRouter)

// 创建路由对象
const router = new VueRouter({
  routes: [
    {
      path: '/home',
      name: 'Home',
      component: flag ? Mobile : Home,
    },
    {
      path: '/senate',
      name: 'Senate',
      component: flag ? SenateMobile : Senate,
    },
    {
      path: '/foundation',
      name: 'Foundation',
      component: flag ? FoundationMobile : Foundation,
    },
    {
      path: '/alumni',
      name: 'Alumni',
      component: flag ? AlumniMobile : Alumni,
    },
    {
      path: '/apply',
      name: 'Apply',
      component: flag ? ApplyMobile : Apply,
    },
    {
      path: '/',
      redirect: '/home'
    },
  ],
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop;
    } else {
      return { x: 0, y: 0 }
    }
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function push (location) {
  return originalReplace.call(this, location).catch(err => err)
}

export default router
