<template>
  <div id="app">
    <router-view v-if="flag" />
  </div>
</template>
<script>
  export default {
    // provide() {
    //   return {
    //     reload: this.reload
    //   }
    // },
    created() {
      this.getWebsiteConfig()
    },
    data() {
      return {
        flag: false,
        // isRouterAlive: true,
      }
    },
    methods: {
      //官网配置
      getWebsiteConfig() {
        this.$http.getWebsiteConfig().then((res) => {
          if (res.status == 200) {
            this.configList = res.data.data
            this.flag = true
            this.$store.commit('getConfigList', this.configList)
          } else {
            this.flag = true
          }
        })
      },

      // reload() {
      //   this.isRouterAlive = false
      //   this.$nextTick(function () {
      //     this.isRouterAlive = true
      //   })
      // },
    },
  }
</script>

<style lang="less">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

    /* max-width: 1920px; */

    MARGIN-RIGHT: auto;
    MARGIN-LEFT: auto;
    // overflow-x: hidden;

    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      /*background-color: #ddd;*/
    }


    /*滑块*/
    ::-webkit-scrollbar-thumb {
      background-color: rgb(255, 255, 255);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: rgb(180, 180, 180);
    }


    /*滑道*/
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgb(117, 117, 117);
      border-radius: 10px;
      background-color: rgb(238, 237, 237);
    }

  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
</style>