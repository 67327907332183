
let BASE_URL
// console.log("process.env.VUE_APP_MODE", process.env.VUE_APP_MODE);
if (process.env.VUE_APP_MODE == 'development') {
  // 开发
  BASE_URL = 'https://jci-api.qlclient.com';
  // BASE_URL = 'https://api.jci.cc'; 
} else if (process.env.VUE_APP_MODE == 'test') {
  // 生产
  BASE_URL = 'https://api.jciuatsys.com'; //测试
} else {
  BASE_URL = 'https://api.jci.cc';//正式
}

export {
  BASE_URL,
}
