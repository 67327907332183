(function () {
  var userAgenInfo = navigator.userAgent
  var Agents = new Array('Android', 'iPhone', 'SymbianOS', 'iPad', 'iPod')
  let flag = false
  for (var v = 0; v < Agents.length; v++) {
    if (userAgenInfo.indexOf(Agents[v]) > 0) {
      flag = true
      break
    }
  }
  let vW = window.innerWidth;
  if (vW < 770) {
    flag = true
  }
  if (flag) {
    calc();
    window.addEventListener('resize', calc);
  } else {
    change();
    /* 监听窗口大小发生改变时 */
    window.addEventListener('resize', change, false);
  }
})();

function change() {
  // 1rem == 100px
  baseSize = 100;
  let basePc = baseSize / 1920; // 表示1920的设计图,使用100PX的默认值
  let vW = window.innerWidth; // 当前窗口的宽度
  let rem = vW * basePc; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应font-size值
  document.documentElement.style.fontSize = rem + "px";
}

function calc() {
  var docElement = document.documentElement
  var clientWidthValue = docElement.clientWidth
  docElement.style.fontSize = 100 / 750 * clientWidthValue + 'px'
}

