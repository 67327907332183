import Vue from 'vue'
import App from './App.vue'

// 导入路由对象
import router from './router/index.js'

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import './assets/css/element_style.css'
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI, { locale });

import VueAwesomeSwiper from 'vue-awesome-swiper' //swiper
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper) //注册swiper
import './assets/SwiperAnimate/animate.min.css'
import './assets/SwiperAnimate/swiper.animate1.0.3.min'


import animated from 'animate.css'

Vue.use(animated)
import $ from 'jquery'
import 'bootstrap'
// 全局注册 $
Vue.prototype.$ = $
// 引入bootstrap样式
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import './assets/css/base.css'
import http from './http/api';
import '../static/rem.js';
import store from './store'

// 导入vant
import './plugins/vant.js'

Vue.use(http)
Vue.config.productionTip = false
new Vue({
  render: (h) => h(App),
  router,
  store
}).$mount('#app')
