import request from './request'

export default {
  //获取官网轮播图
  getWebsiteCarousel(params) {
    return request({
      url: '/api/getWebsiteCarousel',
      method: 'get',
      params,
    })
  },
  //获取官网项目列表
  getWebsiteProjectList(params) {
    return request({
      url: '/api/getWebsiteProjectList',
      method: 'get',
      params,
    })
  },
  //获取官网首页模块信息
  getWebsiteIndexInfo(params) {
    return request({
      url: '/api/getWebsiteIndexInfo',
      method: 'get',
      params,
    })
  },
  //获取官网新闻列表
  getWebsiteNewsList(params) {
    return request({
      url: '/api/getWebsiteNewsList',
      method: 'get',
      params,
    })
  },
  //获取官网新闻信息
  getWebsiteNewsInfo(params) {
    return request({
      url: '/api/getWebsiteNewsInfo',
      method: 'get',
      params,
    })
  },
  //获取官网基本配置信息
  getWebsiteBasicConfig(params) {
    return request({
      url: '/api/getWebsiteBasicConfig',
      method: 'get',
      params,
    })
  },
  //获取官网合作伙伴/赞助商
  getWebsitePartnerList(params) {
    return request({
      url: '/api/getWebsitePartnerList',
      method: 'get',
      params,
    })
  },
  //获取官网配置
  getWebsiteConfig(params) {
    return request({
      url: '/api/getWebsiteConfig',
      method: 'get',
      params,
    })
  },
  //获取官网BOD列表
  getWebsiteBoardList(params) {
    return request({
      url: '/api/getWebsiteBoardList',
      method: 'get',
      params,
    })
  },
  //获取官网模块配置
  getWebsiteModuleList(params) {
    return request({
      url: '/api/getWebsiteModuleList',
      method: 'get',
      params,
    })
  },
  
  // 大使列表
  getWebsiteAmbassadorList(params) {
    return request({
      url: '/api/getWebsiteAmbassadorList',
      method: 'get',
      params,
    })
  },
  //语言列表
  getWebsiteLangList(data) {
    return request({
      url: '/api/getLanguage',
      method: 'POST',
      data,
    })
  },
  getReferendum(params) {
    return request({
      url: '/api/getReferendum',
      method: 'get',
      params,
    })
  },
  getReferendumUsers(params) {
    return request({
      url: '/api/getReferendumUsers',
      method: 'get',
      params,
    })
  },
  checkReferendum(data) {
    return request({
      url: '/api/checkReferendum',
      method: 'POST',
      data,
    })
  },
  
}
