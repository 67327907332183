
import index from './index'

const http = {
   ...index,
}

const install = function (Vue) {
    Vue.prototype.$http = http
}

export default install