import axios from 'axios';
import { BASE_URL } from '../baseurl';

// console.log("BASE_URL", BASE_URL);
export default function $axios (options) {
  return new Promise(async (resolve, reject) => {
    const service = axios.create({
      baseURL: BASE_URL,
      timeout: 30000
    })
    // 请求处理
    service(options).then(res => {
      resolve(res)
    }).catch(error => {
      reject(error)
    })
  })
}
